import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle: "SpotOn Case Study | Rummell Jewelry",
    pageDescription:
      "A jewelry store plans for growth with SpotOn Retail.",
    title: "Modern Tech for Timeless Pieces",
    subTitle: `How an independent jewelry store modernized its payment process with SpotOn Retail.`,
    businessName: "Rummell Jewelry",
    industry: "Retail",
    location: "Littleton, Colorado",
    products: [
      spotonProducts.RETAIL,
    ],
    description:
      "When Drew Rummell bought a jewelry company 11 years ago, he was a recent college grad entering a new industry. Since then, he and the business have matured together. They’ve seen plenty of change in their shared lifespan. 3D printing and laser repair transformed the jewelry industry and independent retailers gained new tools with the rise of point-of-sale technology. Now, Rummell is committed to leveraging this new technology to modernize his jewelry design process and streamline his business operations.",
    quoteData: {
      imgName: "rummell-jewelry.png",
      quote: `“It saves me a ton of time having everything accessible online. The SpotOn Dashboard is super helpful for reconciliation and accounting. Being able to import and export files and save spreadsheets has really streamlined the process.”`,
      personName: "Drew Rummel",
      personTitle: "Owner, Rummell Jewelry",
    },
    goal: `Rummell Jewelry has gone from catering to a small but loyal base to reaching new customers with their beautiful, customizable designs. Their sales team needed tools for taking off-premise payments while closing deals on the go. Rummell, running a lean operation, was looking for cloud-based technology to streamline monthly admin tasks.`,
    solution: `Where customers previously had to come into the store to process payments, SpotOn Retail lets Rummell send invoices and swipe credit cards with a card reader. Rummell can keep track of sales using the SpotOn Dashboard and easily export monthly data for tax and accounting purposes. Spending less time on admin means Rummell can dedicate more time towards planning for future stores and growing the Rummell Jewelry team and brand.`,
    results: {
      title: `The results`,
      stats: [
        { title: `1 hour`, description: `saved weekly on accounting` },
        {
          title: `29% of total sales`,
          description: `Processed through invoicing over the past 6 months`,
        },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
        title: "A blooming nursery gives customers a faster experience",
        imageName: "unique-plants-and-palms.png",
        industry: resourceIndustries.RETAIL,
        tag: resourceTags.CASE_STUDY,
        date: "2022-07-13",
        href: "/case-studies/unique-plants-and-palms",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
        ],
    },
    {
        title: "Getting more regulars with rewards",
        imageName: "strows-dry-cleaners.png",
        industry: resourceIndustries.RETAIL,
        tag: resourceTags.CASE_STUDY,
        date: "2022-05-27",
        href: "/case-studies/strows-dry-cleaners",
        goals: [
          resourceGoals.BUILD_CUSTOMER_LOYALTY,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
    {
        title: "Fresh cuts and faster sales",
        imageName: "get-faded-barbershop.png",
        industry: resourceIndustries.RETAIL,
        tag: resourceTags.CASE_STUDY,
        date: "2022-09-22",
        href: "/case-studies/get-faded-barbershop",
        goals: [
          resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
  ];
  